import React from "react";
import Slider from "react-slick";
import { Box, Typography, useMediaQuery } from "@mui/material";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useTheme } from "@mui/material/styles";

const imageSlides = [
  {
    image: "/assets/pictures/jm-sg/img_001.jpg",
    description:
      "Nuestra historia comenzó hace más de diez años, en un tiempo en que éramos jóvenes y soñadores, ansiosos por amar. ",
  },
  {
    image: "/assets/pictures/jm-sg/img_002.jpg",
    description:
      "No sabíamos que aquel primer encuentro en la preparatoria sería el inicio de un viaje inolvidable.",
  },
  {
    image: "/assets/pictures/jm-sg/img_003.jpg",
    description: "En estos 10 años, hemos vivido muchas aventuras juntos.",
  },
  {
    image: "/assets/pictures/jm-sg/img_004.jpg",
    description: "Hemos crecido juntos, individualmente y como pareja.",
  },
  {
    image: "/assets/pictures/jm-sg/img_005.jpg",
    description:
      "Y cada risa compartida, cada lágrima y cada aventura nos han unido más. ",
  },
];

const ImageSlider = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const sliderRef = React.useRef(null); // Reference to the Slider

  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 3000,
    arrows: false,
  };

  const imgSx = isMobile
    ? {
        width: "100%",
        borderRadius: "10px",
        height: "600px",
        objectFit: "cover",
        objectPosition: "center",
        filter: "grayscale(100%)",
      }
    : {
        width: "100%",
        borderRadius: "10px",
        height: "600px",
        objectFit: "cover",
        filter: "grayscale(100%)",
      };

  const ourStoryContainer = isMobile
    ? {
        width: window.innerWidth - 25 + "px",
        margin: "0 auto",
        mt: 5,
        height: "680px",
      }
    : {
        width: "480px",
        margin: "0 auto",
        mt: 5,
        height: "680px",
      };

  const handleClick = (index) => {
    if (sliderRef.current) {
      sliderRef.current.slickGoTo(index + 1);
    }
  };

  return (
    <Box sx={ourStoryContainer}>
      <Slider {...sliderSettings} ref={sliderRef}>
        {imageSlides.map((slide, index) => (
          <Box
            key={index}
            sx={{ position: "relative", textAlign: "center" }}
            onClick={() => handleClick(index)}
          >
            <img src={slide.image} style={imgSx} />
            <Box
              sx={{
                position: "absolute",
                top: "80%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                color: "#fff",
                backgroundColor: "rgba(0, 0, 0, 20%)",
                padding: "10px",
                borderRadius: "0",
                width: "100%",
              }}
            >
              {/* Title */}
              {slide.title && (
                <Typography variant="h4">{slide.title}</Typography>
              )}
              {/* Description */}
              <Typography variant="p" sx={{ fontSize: "27px" }}>
                {slide.description}
              </Typography>
              {/* Time below */}
              {slide.time && <Typography variant="p">{slide.time}</Typography>}
            </Box>
          </Box>
        ))}
      </Slider>
    </Box>
  );
};

export default ImageSlider;
