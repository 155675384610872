import React from 'react';
import { Box, Typography, Container } from '@mui/material';
import { DressCode } from './dressCode';
import { SelectYourPlace } from './selectYourPlace';
import { SharePictures } from './sharePictures';
const PrepareWedding = () => {

  return (
    <Box pl={3} pr={3} pt={10}>
      <Typography
        variant="h2"
        align="center"
        gutterBottom
        sx={{ fontFamily: "'Dancing Script'" }}
      >
        Prepárate para la boda
      </Typography>

      <Container maxWidth="lg" sx={{ padding: 4 }}>
        <DressCode></DressCode>
        <SelectYourPlace></SelectYourPlace>
        <SharePictures></SharePictures>
      </Container>
    </Box>
  );
};

export default PrepareWedding;
