import React, { useState } from "react";
import {
  Grid2,
  Card,
  CardContent,
  Typography,
  Modal,
  Box,
} from "@mui/material";
import { tableGuestData } from "./tableGuestData";

export const SelectYourPlace = () => {
  const [open, setOpen] = useState(false);
  const [selectedGuests, setSelectedGuests] = useState([]);
  const [selectedTable, setSelectedTable] = useState(0);

  const handleOpen = ({ guests, id }) => {
    setSelectedGuests(guests);
    setSelectedTable(id);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedGuests([]);
  };
  return (
    <Box pl={3} pr={3} pt={10}>
      <Typography
        variant="h2"
        align="center"
        gutterBottom
        sx={{ fontFamily: "'Dancing Script'" }}
      >
        Ubica tu lugar
      </Typography>

      <Box sx={{ backgroundColor: "#92a3a3", padding: "10px" }}>
        <Grid2
          container
          spacing={{ xs: 2, md: 2 }}
          columns={{ xs: 16, sm: 24, md: 24 }}
        >
          {tableGuestData.map((table) => (
            <Grid2 item size={8} key={table.id}>
              <Card
                onClick={() => handleOpen(table)}
                sx={{
                  cursor: "pointer",
                  height: "100%",
                  borderRadius: "16px",
                }}
              >
                <CardContent sx={{ textAlign: "center" }}>
                  <Typography variant="p">Mesa {table.id}</Typography>
                </CardContent>
              </Card>
            </Grid2>
          ))}
        </Grid2>
      </Box>

      {/* Modal to show guest names */}
      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            position: "absolute",
            top: "40%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
          }}
        >
          <Typography
            variant="h4"
            sx={{ fontFamily: "'Lato', sans-serif" }}
          >{`Invitados de mesa ${selectedTable}`}</Typography>
          {selectedGuests.map((guest, index) => (
            <Typography key={index} variant="body2">
              {guest}
            </Typography>
          ))}
        </Box>
      </Modal>
    </Box>
  );
};
