export const hotelInfo = {
  overview: "El Hotel Krystal Grand Nuevo Vallarta es un lujoso resort frente a la playa ubicado en Nuevo Vallarta, Nayarit, México. Ofrece una experiencia familiar El hotel opera bajo un plan todo incluido que proporciona diversas opciones gastronómicas.",
  sections: [
    {
      category: 'Instalaciones',
      src: '/assets/pictures/hotel/recamara.jpg',
      title: 'Recámara',
      desc: 'Cuenta con 410 habitaciones y suites, en las cuales encontrarán cama baño con regadera, closet, secadora de cabello, TV con cable, mini bar. Room service ($)',
      listDesc: []
    },
    {
      category: 'Instalaciones',
      src: '/assets/pictures/hotel/piscinas_2.jpg',
      title: 'Piscinas',
      desc: 'Cuenta con 3 piscinas que ofrecen lo necesario para que cada huésped viva momentos divertidos.',
      listDesc: [
        'Las albercas de Unique para familia y amigos.',
        'Chapoteadero exclusivo para niños que forma parte de Kamp Krystal',
        'Piscina infinity solo para adultos'
      ]
    },
    {
      category: 'Instalaciones',
      src: '/assets/pictures/hotel/spa.webp',
      title: 'Gimnasio y Spa',
      desc: '',
      listDesc: [
        'Gimnasio totalmente equipado en un horario de 09:00 a 19:00 hrs.',
        'Spa ($$) con regaderas de lluvia y sauna con masajes corporales, faciales, manicuras/pedicuras en un horario de 9:00 a 17:00 hrs'
      ]
    },
    {
      category: 'Instalaciones',
      src: '/assets/pictures/hotel/kamp-krystal.webp',
      title: 'Kamp Krystal',
      desc: 'Para menores entre 4 y 9 años. Horario sujeto a disponibilidad',
      listDesc: []
    },
    {
      category: 'Restaurantes',
      title: 'Restaurantes',
      desc: 'Incluye 4 restaurantes, entre ellos  buffet y a la carta que sirven cocina internacional, asiática y mexicana. Además de 5 bares y lounges.',
      listDesc: []
    },
    {
      category: 'Restaurantes',
      src: '/assets/pictures/hotel/restaurante-take-bite.webp',
      title: ' Cafetería Deli Take a Bite',
      desc: 'Horario: 07:00 - 22:00 hrs'
    },
    {
      category: 'Restaurantes',
      src: '/assets/pictures/hotel/restaurante_ayami.webp',
      title: 'Restaurante Ayami',
      desc: 'Especialidades de la comida japonesa, tailandesa, china e hindú.',
      listDesc: [
        'Código de vestir cena: Casual elegante',
        'Requiere reservación previa',
        'Horario: 18:00 - 22:30 hrs'
      ]
    },
    {
      category: 'Restaurantes',
      src: '/assets/pictures/hotel/restaurante_grill.jpg',
      title: 'Restaurante The Grill',
      desc: 'Especialidades de la comida italiana y cortes de carne',
      listDesc: [
        'Código de vestir cena: Casual elegante',
        'Requiere reservación previa',
        'Horario: 18:00 - 22:30 hrs'
      ]
    },
    {
      category: 'Restaurantes',
      src: '/assets/pictures/hotel/restaurante_mortero.webp',
      title: 'Restaurante Hacienda El Mortero',
      desc: 'Especialidades de la comida mexicana',
      listDesc: [
        'Código de vestir cena: Casual elegante',
        'Requiere reservación previa',
        'Horario: 18:00 - 22:30 hrs'
      ]
    },
    {
      category: 'Restaurantes',
      src: '/assets/pictures/hotel/restaurant_o.webp',
      title: 'Restaurante O',
      desc: 'Buffet internacional con diversas opciones en desayuno, comida y cena.',
      listDesc: [
        'Código de vestir cena: Casual',
        'No requiere reservación',
        ['Horarios', [
          'Desayuno: 07:00 - 12:00 hrs',
          'Comida: 13:00 - 17:00 hrs',
          'Cena: 18:00 - 22:30 hrs'
        ]]
      ]
    }
  ]
}