import React, { useEffect, useState, useCallback, useMemo } from 'react';

const Countdown = () => {
  const weddingDate = useMemo(() => new Date('2024-11-30T17:30:00'), []);

  const [timeRemaining, setTimeRemaining] = useState({
    days: '00',
    hours: '00',
    minutes: '00',
    seconds: '00',
  });

  const updateCountdown = useCallback(() => {
    const now = new Date().getTime();
    const distance = weddingDate - now;

    if (distance < 0) {
      setTimeRemaining({
        days: '00',
        hours: '00',
        minutes: '00',
        seconds: '00',
      });
      return;
    }

    const days = Math.floor(distance / (1000 * 60 * 60 * 24));
    const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((distance % (1000 * 60)) / 1000);

    setTimeRemaining({
      days: days < 10 ? `0${days}` : days,
      hours: hours < 10 ? `0${hours}` : hours,
      minutes: minutes < 10 ? `0${minutes}` : minutes,
      seconds: seconds < 10 ? `0${seconds}` : seconds,
    });
  }, [weddingDate]);

  useEffect(() => {
    const interval = setInterval(updateCountdown, 100); // Updated to 100ms for a realistic countdown
    return () => clearInterval(interval);
  }, [updateCountdown]);

  return (
    <div id="countdown" className="countdown">
      <div className="countdown-item">
        <span>{timeRemaining.days}</span>
        <p>Dias</p>
      </div>
      <div className="countdown-item">
        <span>{timeRemaining.hours}</span>
        <p>Horas</p>
      </div>
      <div className="countdown-item">
        <span>{timeRemaining.minutes}</span>
        <p>Minutos</p>
      </div>
      <div className="countdown-item">
        <span>{timeRemaining.seconds}</span>
        <p>Segundos</p>
      </div>
    </div>
  );
};

export default Countdown;
