import React, { useState, useEffect } from "react";
import { Box, Button, Grid2, TextField, Typography } from "@mui/material";
import axios from "axios";

export const BestWishes = () => {
  const [loading, setLoading] = useState(true);
  const [wishes, setWishes] = useState([]);
  const [error, setError] = useState("");
  const [wish, setWish] = useState({
    name: "",
    wishes: "",
  });
  const [deviceInfo, setDeviceInfo] = useState({ ip: "", device: "" });
  const URL = `${process.env.REACT_APP_API_URL}/wishes`;

  // Fetch IP Address and Device Information
  useEffect(() => {
    // Get IP address
    const fetchIpAddress = async () => {
      try {
        const response = await axios.get("https://api.ipify.org?format=json");
        const ipAddress = response.data.ip;

        // Get Device Information
        const deviceDetails = `${navigator.platform}`;

        setDeviceInfo({ ip: ipAddress, device: deviceDetails });
      } catch (error) {
        console.error("Error fetching IP and Device Information:", error);
      }
    };

    fetchIpAddress();
  }, []);

  // Fetch existing wishes
  const fetchWishes = async () => {
    try {
      const response = await axios.get(URL);
      setWishes(response.data);
    } catch (err) {
      console.error(err);
      setError("Error fetching wishes");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchWishes();
  }, []);

  const handleChange = (e, text, length) => {
    const { id, value } = e.target;
    // Check if the new value's length exceeds the limit
    if (value.length <= length) {
      setWish({ ...wish, [id]: value });
    } else {
      setWish({ ...wish, [id]: value.slice(0, length) }); // Limit the pasted text
    }
  };

  // Create a new wish, including IP and device info
  const handleSubmit = async (event) => {
    event.preventDefault(); // Prevent page refresh
    try {
      if (wish.name === "" && wish.wishes === "") {
        setError("Nombre y/o deseos están vacíos");
        console.error(error);
        return;
      } else {
        await axios.post(URL, {
          wish: {
            ...wish,
            ip: deviceInfo.ip,
            device: deviceInfo.device,
          },
        });
        setWish({
          name: "",
          wishes: "",
        });
        fetchWishes();
      }
    } catch (error) {
      if (error.status && error.status === 406) {
        setError("Usuario y/o deseos ya han sido registrado previamente");
      }
      console.error("Error adding wish", error.message);
    }
  };

  return (
    <Box sx={{ padding: 2 }}>
      <Typography
        variant="h3"
        align="center"
        gutterBottom
        sx={{ fontFamily: "'Dancing Script', cursive" }}
      >
        Registra tus mejores deseos
      </Typography>

      <Box
        component="form"
        onSubmit={handleSubmit}
        noValidate
        autoComplete="off"
        sx={{
          "& .MuiTextField-root": { m: 1, width: "100%" },
          maxWidth: 500,
          margin: "0 auto",
        }}
      >
        <Grid2 container spacing={2}>
          {error.length > 0 && (
            <span
              style={{
                fontFamily: "Monserrat",
                color: "#d35454",
                marginLeft: "1em",
              }}
            >
              {error}
            </span>
          )}
          <Grid2 item xs={12}>
            <TextField
              id="name"
              label="Nombre"
              variant="outlined"
              required
              fullWidth
              value={wish.name}
              onChange={(e) => handleChange(e, wish.name, 25)}
              helperText={`${wish.name.length}/25 caracteres`}
            />
          </Grid2>
          <Grid2 item xs={12}>
            <TextField
              id="wishes"
              label="Deseos"
              variant="outlined"
              required
              fullWidth
              multiline
              rows={4}
              value={wish.wishes}
              onChange={(e) => handleChange(e, wish.wishes, 240)}
              helperText={`${wish.wishes.length}/240 caracteres`}
            />
          </Grid2>
          <Grid2 item xs={12}>
            <Button type="submit" variant="outlined" color="neutral" fullWidth>
              Registrar
            </Button>
          </Grid2>
        </Grid2>
      </Box>

      {wishes.length > 0 ? (
        <Typography
          variant="h3"
          textAlign={"center"}
          fontFamily={"'Dancing Script', cursive"}
          sx={{ marginTop: 2 }}
        >
          Mejores Deseos
        </Typography>
      ) : (
        ""
      )}
      <Box
        id="wishesData"
        sx={{ marginTop: 4, height: "22em", overflowY: "scroll", border: '1px solid #ccc', borderRadius: '6px' }}
      >
        {wishes.length > 0 &&
          wishes.map(({ name, wishes }, index) => (
            <Box
              key={index}
              sx={{
                padding: 2,
                borderBottom: "1px solid #ddd",
                marginBottom: 2,
              }}
            >
              <Typography
                variant="subtitle1"
                sx={{
                  fontWeight: "bold",
                  fontSize: "1.3em",
                  fontFamily: "Dancing Script",
                }}
              >
                {name}
              </Typography>
              <Typography variant="body1">{wishes}</Typography>
            </Box>
          ))}
      </Box>
    </Box>
  );
};
