import React from "react";
import "./videoBackground.css";
import Countdown from "../countdown";
import CalendarOptions from "../calendar";
import "./videoBackground.css";

import { Typography } from "@mui/material";

const VideoBackground = () => {
    const assetsFolderURL = "../../assets/videos";
    const videoURL =
        window.innerWidth < 768
            ? `${assetsFolderURL}/wedding_new_compressed_720.mp4`
            : `${assetsFolderURL}/wedding_new_compressed_1080.mp4`;
    return (
        <div className="video-background" id="home">
            <video autoPlay muted loop playsInline id="background-video">
                <source src={videoURL} type="video/mp4" />
                Your browser does not support the video tag.
            </video>
            <div className="content">
                <Typography
                    variant="h3"
                    align="center"
                    sx={{
                        fontFamily: "'Dancing Script', cursive",
                        fontWeight: 700,
                        width: "8em",
                        margin: "0 auto",
                    }}
                >
                    Samantha Gonzalez &amp; Jonathan Morelos
                </Typography>

                <Typography
                    variant="h4"
                    align="center"
                    sx={{ fontFamily: "'Dancing Script'" }}
                >
                    Bienvenidos a nuestra boda
                </Typography>
                <Countdown></Countdown>
                <div className="calendar-button-container">
                    <CalendarOptions></CalendarOptions>
                </div>
            </div>
        </div>
    );
};

export default VideoBackground;
