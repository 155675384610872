import React from "react";
import { Box, Typography, Link, Grid2, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

export const SharePictures = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <Box pl={3} pr={3} pt={10} sx={{ textAlign: 'center' }}>
      <Typography
        variant="h2"
        align="center"
        gutterBottom
        sx={{ fontFamily: "'Dancing Script'" }}
      >
        Compártenos tus fotos el día del evento
      </Typography>
      <Box>
        <Typography variant="h2" style={{
          background: 'linear-gradient(279deg, #ef8400, #1678d6)',
          backgroundClip: 'text',
          color: ' #00000000',
          fontWeight: '700',
        }}>
          Dots Memories
        </Typography>
      </Box>
      <Typography variant="p" sx={{ fontSize: '2em' }} >
        Descarga con anticipación la aplicación <Link href="https://dotstheapp.com/" target="_blank"
          sx={{ fontFamily: "Lareto, sans-serif !important" }}>“Dots memories”</Link> y despues escanea el siguiente código qr.
      </Typography>
      <Box mt={4}>
        <img src="/assets/pictures/qr/dots_qr.png" style={{ width: '200px' }} />
      </Box>
    </Box >
  );
}