import React from "react";
import VideoBackground from "../videoBackground";
import OurStory from "./home-sections/ourStory/ourStory";
const Home = () => {
  return (
    <div>
      <VideoBackground />
      <OurStory />
    </div>
  );
};

export default Home;
