import React, { useRef } from "react";
import PropTypes from "prop-types";
import { Box, Typography, List, ListItem, ListItemText } from "@mui/material";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { isMobile } from "../../utils/utils";

// Dress code instructions component
const DressCodeInstructions = ({ options }) => (
  <Box
    sx={{
      backgroundColor: "transparent",
      boxShadow: 0,
      width: "20em",
      position: "relative",
      right: "15px",
    }}
  >
    <List>
      {options.map((option, index) => (
        <ListItem key={index}>
          <ListItemText
            primary={`• ${option}`}
            sx={{ fontSize: "1.5em", fontFamily: "Lareto" }}
          />
        </ListItem>
      ))}
    </List>
  </Box>
);

DressCodeInstructions.propTypes = {
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
};

// Image slider component
const DressCodeSlider = ({ images, sliderRef, handleClick, type }) => {
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    arrows: false,
  };

  const boxSliderStyles = isMobile()
    ? { display: "block", width: "100%", height: "auto", margin: "0 auto" }
    : { display: "block", width: "600px", height: "auto" };

  return (
    <Box
      sx={{ ...boxSliderStyles, backgroundColor: "transparent", boxShadow: 0 }}
    >
      <Slider key={type} {...sliderSettings} ref={sliderRef}>
        {images.map((imageSrc, index) => (
          <Box key={index} onClick={() => handleClick(sliderRef, index)}>
            <img
              key={index + type}
              src={imageSrc}
              alt={`Dress code ${index + 1}`}
              style={{
                width: "100%",
                height: "400px",
                objectFit: "cover",
                display: "block",
              }}
            />
          </Box>
        ))}
      </Slider>
    </Box>
  );
};

DressCodeSlider.propTypes = {
  images: PropTypes.arrayOf(PropTypes.string).isRequired,
  sliderRef: PropTypes.object.isRequired,
  handleClick: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
};

// Main DressCode component
export const DressCode = () => {
  const sliderManRef = useRef(null);
  const sliderWomanRef = useRef(null);

  const handleClick = (sliderRef, index) => {
    sliderRef.current?.slickGoTo(index + 1);
  };

  const dressOptions = {
    hombres: [
      "NO guayabera de manga corta",
      "NO shorts ni pesqueros ni chanclas",
      "De preferencia, no traje",
    ],
    mujeres: ["NO vestidos cortos", "NO colores lila/morado"],
  };

  const manDressCodeImg = [
    "/assets/pictures/dress-code/dresscode_1.jpeg",
    "/assets/pictures/dress-code/dresscode_2.jpeg",
    "/assets/pictures/dress-code/dresscode_3.jpeg",
  ];

  const womanDressCodeImg = [
    "/assets/pictures/dress-code/dresscodewm_1.jpeg",
    "/assets/pictures/dress-code/dresscodewm_2.jpeg",
    "/assets/pictures/dress-code/dresscodewm_3.jpeg",
  ];

  return (
    <Box sx={{ padding: 3 }}>
      <Typography
        variant="h3"
        sx={{ fontFamily: "Dancing Script", textAlign: "center", mb: 2 }}
      >
        Código de vestimenta para hombres:
      </Typography>
      <Box sx={{ display: isMobile() ? "block" : "flex" }}>
        {/* Men's Dress Code */}
        <DressCodeInstructions options={dressOptions.hombres} />
        <DressCodeSlider
          images={manDressCodeImg}
          sliderRef={sliderManRef}
          handleClick={handleClick}
          type={"man"}
        />
      </Box>

      {/* Women's Dress Code */}
      <Typography
        variant="h3"
        sx={{ fontFamily: "Dancing Script", textAlign: "center", mt: 4, mb: 2 }}
      >
        Código de vestimenta para mujeres:
      </Typography>
      <Box sx={{ display: isMobile() ? "block" : "flex" }}>
        <DressCodeInstructions options={dressOptions.mujeres} />
        <DressCodeSlider
          images={womanDressCodeImg}
          sliderRef={sliderWomanRef}
          handleClick={handleClick}
          type={"woman"}
        />
      </Box>
    </Box>
  );
};
