export const events = [
  {
    title: "Itinerario",
    subtitle: "Ceremonia",
    date: "30 de Noviembre 2024",
    time: "5:30pm",
    location: "Hotel Krystal Grand Nuevo Vallarta Playa",
    image: "/assets/pictures/itinerary/ceremony.jpg",
    attire:
      "Attire: Caftans y vestimenta casual de verano. ¡Prepárate para disfrutar del aire fresco del océano!",
  },
  {
    title: "Itinerario",
    subtitle: "Coctel",
    date: "30 de Noviembre 2024",
    time: "6:10pm",
    location: "Hotel Krystal Grand Nuevo Vallarta Terraza",
    image: "/assets/pictures/itinerary/cocktail.jpg",
    attire: "Attire: Formal, con opción de corbata negra.",
  },
  {
    title: "Itinerario",
    subtitle: "Recepcion",
    date: "30 de Noviembre 2024",
    time: "6:50pm",
    location: "Hotel Krystal Grand Nuevo Vallarta Terraza",
    image: "/assets/pictures/itinerary/reception.jpg",
    attire: "Attire: Formal, con opción de corbata negra.",
  },
  {
    title: "Itinerario",
    subtitle: "Baile de novios",
    date: "30 de Noviembre 2024",
    time: "7:00pm",
    location: "Hotel Krystal Grand Nuevo Vallarta Terraza",
    image: "/assets/pictures/itinerary/baile-novios.jpeg",
    attire: "Attire: Casual, pero elegante.",
  },
  {
    title: "Itinerario",
    subtitle: "Baille de papas",
    date: "30 de Noviembre 2024",
    time: "7:10pm",
    location: "Hotel Krystal Grand Nuevo Vallarta Terraza",
    image: "/assets/pictures/itinerary/baile-papas.jpeg",
    attire: "Attire: Casual, pero elegante.",
  },
  {
    title: "Itinerario",
    subtitle: "Brindis",
    date: "30 de Noviembre 2024",
    time: "7:15pm",
    location: "Hotel Krystal Grand Nuevo Vallarta Terraza",
    image: "/assets/pictures/itinerary/brindis.jpeg",
    attire: "Attire: Casual, pero elegante.",
  },
  {
    title: "Itinerario",
    subtitle: "Cena",
    date: "30 de Noviembre 2024",
    time: "7:20pm",
    location: "Hotel Krystal Grand Nuevo Vallarta Terraza",
    image: "/assets/pictures/itinerary/dinner.jpeg",
    attire: "Attire: Casual de fiesta.",
  },
  {
    title: "Itinerario",
    subtitle: "Fiesta",
    date: "30 de Noviembre 2024",
    time: "8:00pm",
    location: "Hotel Krystal Grand Nuevo Vallarta Terraza",
    image: "/assets/pictures/itinerary/wedding_party.jpg",
    attire: "Attire: Casual de fiesta.",
  },
];
