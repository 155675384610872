export const tableGuestData = [
  {
    id: 1,
    guests: [
      'PILAR AVILA',
      'GERARDO LOPEZ',
      'MATÍAS LOPEZ',
      'NORA MARTÍNEZ SUSTAITA',
      'JESSICA MORELOS MARTÍNEZ',
      'ANTONIO CISNEROS',
      'ISAAC CISNEROS',
      'JOEL MORELOS',
      'JOEL MORELOS MTZ',
    ],
  },
  {
    id: 2,
    guests: [
      'LUCIA AVILA CRUZ',
      'ANGEL ROBERTO GONZÁLEZ AVILA',
      'ANGEL ROBERTO GONZÁLEZ SOTO',
      'RAISA GONZÁLEZ AVILA',
      'NESTOR CANTÚ GONZÁLEZ',
      'CHRISTOPHER CANTÚ',
      'DAFNE GONZÁLEZ AVILA',
      'WILLIAM PARENTY',
      'EVERGEM AVILA',
    ],
  },
  {
    id: 3,
    guests: [
      'HERMELINDA ÁVILA',
      'MELISSA CALVILLO',
      'ADRIANA CALVILLO',
      'MA TERESA AVILA',
      'ANGEL ALVARADO',
      'ORLANDO ALVARADO',
      'CAROLINA CALVILLO',
      'BRUNO GONZÁLEZ',
      'CATALINA GONZÁLEZ',
    ],
  },
  {
    id: 4,
    guests: [
      'ADRIAN PADILLA',
      'HECTOR ORTIZ',
      'KEVIN ESQUIVEL',
      'XIOMARA CANTÚ',
      'ANGEL GLORIA',
      'PERLA MARTINEZ',
      'DAVID FOSTER',
      'HILLARY CONTRERAS',
    ],
  },
  {
    id: 5,
    guests: [
      'ANA CANTÚ',
      'CARLOS GONZÁLEZ',
      'KARLA CORTES',
      'FERNANDO GARZA',
      'JESSICA DELGADO',
      'EDUARDO CARRANZA',
      'CINTHIA GUAJARDO',
      'ARMANDO SILLER',
    ],
  },
  {
    id: 6,
    guests: [
      'REBECA TREVIÑO',
      'ASHLEY CRUZ',
      'IVONNE ROMERO',
      'ALFONSO VELAZQUEZ',
      'ALONDRA HERNÁNDEZ',
      'CLAUDIA IBARRA',
      'ISAAC AMADOR',
      'DIEGO NOVELO',
    ],
  },
  {
    id: 7,
    guests: [
      'JAIMIR GONZÁLEZ',
      'AARON DAVILA',
      'DANIEL ARREDONDO',
      'MELANIE MARCHANT',
      'BENJAMIN DELGADO',
      'MARÍA DELGADILLO',
      'JUAN GUAJARDO',
      'LUZ AGUAYO',
    ],
  },
  {
    id: 8,
    guests: [
      'ANDREA TREVIÑO',
      'DEBORAH RIVERA',
      'MAFER GARZA',
      'KATHYA VILLA',
      'MARCELO HERNANDEZ',
      'PATRICIA CHAPA',
      'JUAN CARLOS LOERA',
      'LITZY MOLINA',
    ],
  }
];