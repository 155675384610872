import React from "react";
import { Box, Typography, Container } from "@mui/material";
import Itinerary from "../itinerary/itinerary";
import ImageSlider from "./imageSlider";
import "./ourStory.css";
import { BestWishes } from "../bestWishes/bestWishes";

const OurStory = () => {
  return (
    <Box>
      {/* Itinerary slider */}
      <Box id="itinerary" sx={{ py: 4 }}>
        <Itinerary />
      </Box>
      {/* Our story pictures Image slider */}
      <Box id="our-story" sx={{ py: 4, backgroundColor: "#fcf7ea" }}>
        <Container>
          <Typography
            variant="h3"
            component="h1"
            align="center"
            gutterBottom
            sx={{ fontFamily: "'Dancing Script'" }}
          >
            Nuestra historia
          </Typography>

          <ImageSlider />
        </Container>
      </Box>
      {/* Best wishes feature */}
      <Box id="bestWishes" sx={{ py: 4 }}>
        <BestWishes />
      </Box>
    </Box>
  );
};

export default OurStory;
