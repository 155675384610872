import React from "react";
import Slider from "react-slick";
import { Box, Typography, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { events } from "./events";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./itinerary.css";

const Itinerary = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const sliderRef = React.useRef(null); // Reference to the Slider

  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 3000,
    arrows: false,
  };

  const imgSx = isMobile
    ? {
        width: "100%",
        borderRadius: "10px",
        height: "600px",
        objectFit: "cover",
        objectPosition: "center",
      }
    : {
        width: "100%",
        borderRadius: "10px",
        height: "600px",
        objectFit: "cover",
      };

  const containerCss = isMobile
    ? {
        width: window.innerWidth - 25 + "px",
        margin: "0 auto",
        mt: 5,
        height: "680px",
      }
    : {
        width: "480px",
        margin: "0 auto",
        mt: 5,
        height: "680px",
      };

  const handleClick = (index) => {
    if (sliderRef.current) {
      sliderRef.current.slickGoTo(index + 1);
    }
  };

  return (
    <Box sx={containerCss}>
      <Typography
        variant="h3"
        align="center"
        gutterBottom
        sx={{ fontFamily: "'Dancing Script'" }}
      >
        Itinerario
      </Typography>
      <Slider {...sliderSettings} ref={sliderRef}>
        {events.map((slide, index) => (
          <Box
            key={index}
            sx={{ position: "relative", textAlign: "center" }}
            onClick={() => handleClick(index)}
          >
            <img src={slide.image} style={imgSx} />
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                color: "#fff",
                padding: "10px",
                borderRadius: "0",
                backgroundColor: "rgb(0 0 0 / 20%)",
                width: "100%",
              }}
            >
              {/* Title */}
              {slide.title && (
                <Typography
                  variant="h2"
                  sx={{ fontFamily: "'Dancing Script'" }}
                >
                  {slide.subtitle}
                </Typography>
              )}

              {/* Time below */}
              {slide.time && (
                <Typography variant="p" sx={{ fontSize: "22px" }}>
                  {slide.time}
                </Typography>
              )}
            </Box>
          </Box>
        ))}
      </Slider>
    </Box>
  );
};

export default Itinerary;
